<template>
  <div v-if="user" class="user" @click="toggleUser" :class="{ selected: selected }">
    <p class="user-name">{{ user.name }}</p>
    <img class="user-avatar" :src="user.avatar" alt="user avatar" height="24">
  </div>
  <div v-else>
    <p><strong>Oooops</strong></p>
  </div>
</template>

<script>

export default {
  name: 'MyUser',
  emits: ['userSelected'],
  data() {
    return {
      selected: this.selected
    };
  },
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  methods: {
    toggleUser() {
      this.selected = !this.selected;
      this.$emit('userSelected', this.user.steamid);
    }
  }
};
</script>

<style scoped>
.user {
  margin: 5px;
  padding: 5px;
  border-radius: 2px;
  background-color: var(--main-background-color);
  color: var(--main-color);
  min-width: 100px;
  opacity: .7;
}
.user.selected {
  opacity: 1;
}
.user-avatar {
  border: 2px solid rgba(83, 164, 196, 1);
}
</style>
