<template>
  <div class="footer">
    <a target="_blank" href="https://discord.gg/Q6YSVFr" class="mobile-hide">DiscordServer</a>
    <label>Made with <MyIcon name="heart" class="icon" /> by PeterSilikum &amp; Ti2l</label>
    <a target="_blank" href="https://steamcommunity.com/groups/8zKrieger" class="mobile-hide">SteamGruppe</a>
  </div>
</template>

<script>
import MyIcon from './Icon.vue';
export default {
  name: 'MyFooter',
  components: { MyIcon }
};
</script>
<style scoped>
.footer {
  background-color: rgb(27, 29, 30);
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  width: 100%;
  font-size: .8rem;
}
.footer a, .footer label {
  color: rgb(164, 157, 145);
  transition: .3s;
  cursor: pointer;
}
.footer label .icon {
  height: .8rem;
  width: .8rem;
  transition: color 1s ease-out;
  position: relative;
  top: 2px;
}
.footer a:hover, .footer label:hover {
  color: rgba(247, 216, 183, 0.9);
}
.footer .icon:hover {
  color: #ef0e33;
}
</style>
