import { createStore } from 'vuex';
import * as api from './api';

const store = createStore({
  state() {
    return {
      user: null,
      init_running: false
    };
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
    initRunning(state) {
      state.init_running = true;
    },
    initRunningOff(state) {
      state.init_running = false;
    }
  },
  actions: {
    async login({ commit }) {
      const user = await api.getMe();
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('clearUser');
      api.logout();
    },
    async init({ commit }) {
      try {
        this.commit('initRunning');
        await api.getLoginStatus();
        await this.dispatch('login');
      } catch (err) {
        console.log(err.message);
        commit('clearUser');
      } finally {
        this.commit('initRunningOff');
      }
    }
  }
});

export default store;
