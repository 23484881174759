<template>
  <MyHeader class="header" />
  <div class="main">
    <router-view class="view" />
  </div>
  <MyFooter class="footer" />
</template>

<script>
import MyHeader from './components/Header';
import MyFooter from './components/Footer.vue';
export default {
  components: { MyHeader, MyFooter }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
}

.header, .footer {
  flex-shrink: 0;
}

.main {
  flex: 1 0 auto;
  margin: 1rem 0 .5rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.main .view {
  width: 70%;
  max-width: 1200px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
}

</style>
