<template>
  <div v-if="game" class="game" :class="{ edit: editMode, hidden: game.hidden }" @click="toggleHidden" >
    <div v-if="editMode" >
      <img
        :src="game_img_url"
        :alt="game.name"
      />
    </div>
    <a v-else :href="game_store_url" target="blank">
      <img
        :src="game_img_url"
        :alt="game.name"
        @load="imageLoad"
      />
    </a>
    <p class="game-name">{{ game.name }}</p>
    <div v-if="game.hidden" class="icon"><MyIcon name="eye-off" /></div>
  </div>
  <div v-else>
    <p><strong>Oooops</strong></p>
  </div>
</template>

<script>
import MyIcon from '../components/Icon.vue';
import { gameImgUrl, gameStoreUrl } from '../services/api';

export default {
  name: 'MyGame',
  components: { MyIcon },
  emits: ['gameClicked', 'imgLoaded'],
  props: {
    game: {
      type: Object,
      default: null
    },
    editMode: Boolean
  },
  computed: {
    game_img_url() {
      return gameImgUrl(this.game.appid, this.game.img_icon);
    },
    game_store_url() {
      return gameStoreUrl(this.game.appid);
    }
  },
  methods: {
    toggleHidden() {
      if (!this.editMode) return;
      this.$emit('gameClicked', this.game.appid);
    },
    imageLoad() {
      this.$emit('imgLoaded', this.game.appid);
      // console.log(`${this.game.appid} img was loaded`);
    }
  }
};
</script>

<style scoped>
.game {
  position: relative;
  display: flex;
  column-gap: 10px;
  margin: 5px;
  padding: 5px;
  font-size: 14px;
  background-color: seagreen;
  border-radius: 2px;
  background-color: var(--main-background-color);
  color: var(--main-color);
}
.game-name {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.game img {
  /* width: 100px; */
  position: relative;
  top: 3px;
}
.icon {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.hidden {
  background-color: tomato;
}

.edit {
  cursor: pointer;
}

</style>
