<template>
  <div class="nav">
    <div class="header-centered">
      <div class="logo-wrapper">
        <a href="/" class="mobile-hide">
          <img src="../assets/8zkrieger_logo.png" height="35" border="0" alt="logo" />
        </a>
        <a href="/" class="mobile-hide">
          <img src="../assets/8D.png" height="35" border="0" alt="logo" />
        </a>
      </div>
      <div class="link-wrapper">
      <template v-for="(link, index) of computedLinks" :key="link">
        <router-link class="link" :to="link.path">{{link.name}}</router-link>
        {{ index !== (computedLinks.length - 1) ? ' | ' : null }}
      </template>
      </div>
      <div v-if="loggedIn" class="profile-wrapper">
        <div>
          <p>
            <strong>{{ user.name }}</strong>
          </p>
          <router-link to="/logout" class="link logout">
            logout
          </router-link>
        </div>
        <div class="avatar-wrapper">
          <router-link class="link" to="/profile">
            <img :src="user.avatar" height="35" border="0" alt="user avatar" />
          </router-link>
        </div>
      </div>
      <div v-else class="profile-wrapper">
        <a :href="api_url + '/auth/steam'"
          ><img src="../assets/steam_login_logo.png" width="180" height="35" border="0"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../router';
import { mapGetters } from 'vuex';
export default {
  name: 'MyHeader',
  computed: {
    computedLinks() {
      const routes = router.getRoutes().filter(route => !route.meta.hidden);
      if (!this.loggedIn) return routes.filter(route => !route.meta.requiresAuth);
      else return routes.filter(route => !route.meta.requiresNoAuth);
    },
    user() {
      return this.$store.state.user;
    },
    api_url() {
      return `${process.env.VUE_APP_API_BASE_URL}`;
    },
    ...mapGetters(['loggedIn'])
  }
};
</script>

<style scoped>
img {
  display: block;
}
.logout {
  font-size: 0.8em;
  text-decoration: none;
}
.link {
  cursor: pointer;
  color: rgb(164, 157, 145);
  /* color: var(--main-color); */
  transition: .3s;
}
.link:hover,
.router-link-active {
  color: rgba(247, 216, 183, 0.9);
  /* color: rgba(var(--main-color), 0.9); */
}
.link-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
.logo-wrapper {
  display: flex;
  justify-content: space-between;
}

.profile-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 180px;
  color: #f7d8b7;
  /* color: var(--main-color); */
}
.profile-wrapper .avatar-wrapper {
  margin-left: 0.7em;
}
.avatar-wrapper img {
  border: 2px solid rgba(83, 164, 196, 1);
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: rgb(27, 29, 30);
  /* background-color: var(--main--background-color); */
  box-shadow: rgba(0, 0, 0, 0.67) 0px 0px 3vh;
}

.header-centered {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 1em;
  column-gap: 30px;
}
.header-centered > div {
  flex-basis: 180px;

}

</style>
