import { createRouter, createWebHistory } from 'vue-router';
import store from '../services/store';
import getMe from '../components/getMe.vue';
import Home from '../views/Home.vue';
import Profile from '../views/Profile.vue';
import Selector from '../views/Selector.vue';
import Login from '../views/Login.vue';

const checkAuth = (to, from) => {
  if (to.meta.requiresAuth && !store.getters.loggedIn) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath }
    };
  }
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/selector',
    name: 'Selector',
    component: Selector
    // meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresNoAuth: true },
    beforeEnter: (to, from) => {
      if (store.getters.loggedIn) {
        return {
          path: 'profile'
        };
      }
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from) => {
      store.dispatch('logout');
      return {
        path: '/'
      };
    },
    meta: { hidden: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/me',
    name: 'me',
    component: getMe,
    meta: { hidden: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(checkAuth);
export default router;
