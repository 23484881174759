import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true
});

export function getLoginStatus() {
  return instance.get('/auth/loggedIn')
    .then(res => res.data);
}

export function logout() {
  console.log('logging out');
  return instance.get('/auth/logout')
    .then(res => res.statusText);
}

export function getMe() {
  return instance.get('/api/me')
    .then(res => res.data);
}

export function getMyGames() {
  return instance.get('/api/me/games')
    .then(res => res.data);
}

export function updateMyGames(data) {
  if (!(data instanceof Array)) throw new Error(`${data} is not an array!`);
  return instance.put('/api/me/games', { games: data })
    .then(res => res.data);
}

export function getAllGames() {
  return instance.get('/api/games')
    .then(res => res.data);
}

export function getAllUsers() {
  return instance.get('/api/users')
    .then(res => res.data);
}

export function gameImgUrl(appid, hash) {
  return `https://cdn.cloudflare.steamstatic.com/steam/apps/${appid}/capsule_184x69.jpg`;
  // return `https://media.steampowered.com/steamcommunity/public/images/apps/${appid}/${hash}.jpg`;
}
export function gameStoreUrl(appid) {
  return `https://store.steampowered.com/app/${appid}/`;
}
