<template>
  <div class="toast" :class="classes">
    <span>{{message}}</span>
  </div>
</template>

<script>
export default {
  name: 'MyToast',
  props: {
    message: String,
    type: {
      type: String,
      validator(value) {
        // The value must match one of these strings
        return ['success', 'error'].includes(value);
      }
    }
  },
  computed: {
    classes() {
      return {
        success: this.type === 'success',
        error: this.type === 'error'
      };
    }
  }
};
</script>

<style scoped>
.toast span {
  padding: 5px 15px;
  color: white;
  /* box-shadow: rgba(0, 0, 0, 0.67) 0px 0px 1vh; */
  /* border-radius: 2px; */
}
.toast {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background-color: var(--main-background-color);
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
}
.toast.success {
  background-color: var(--success);
  border: 1px solid var(--success);
}
.toast.error {
  background-color: var(--error);
  border: 1px solid var(--error);
}
</style>
