<template>
  <div>
    <p>Updating</p>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('login').then(() => {
      this.$router.replace({ path: '/' });
    });
  }
};
</script>
