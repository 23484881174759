<template>
  <div class="login">
    <h1>Login</h1>
    <p v-if="redirectParam">
      Hello, please log in to see the content of the
      <code>{{ redirectParam }}</code> Page
    </p>
    <p v-else>Hello, please log in</p>
    <div class="wrapper">
      <a :href="api_url + '/auth/steam'">
        <img src="../assets/steam_login_logo.png" width="180" height="35" border="0"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  computed: {
    redirectParam() {
      return this.$route.query.redirect || false;
    },
    api_url() {
      return `${process.env.VUE_APP_API_BASE_URL}`;
    }
  },
  mounted() {
    document.title = '8zKrieger - Login';
  }
};
</script>

<style scoped>
.wrapper {
  height: 100%;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
