<template>
  <div class="spinner">
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'MySpinner'
};
</script>

<style scoped>
.spinner {
  width: 24px;
  height: 24px;
  position: relative;
}
.spinner div {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top-color: var(--main-color);
  animation: spinnerOne 1.2s linear infinite;
}
.spinner div:nth-child(2) {
  border: 4x solid transparent;
  border-bottom-color: var(--main-color);
  animation: spinnerTwo 1.2s linear infinite;
}

@keyframes spinnerOne {
  0% { transform: rotate(0deg); border-width: 4px;}
  50% { transform: rotate(180deg); border-width: 1px;}
  100% { transform: rotate(360deg); border-width: 4px;}
}
@keyframes spinnerTwo {
  0% { transform: rotate(0deg); border-width: 4px;}
  50% { transform: rotate(180deg); border-width: 1px;}
  100% { transform: rotate(360deg); border-width: 4px;}
}
</style>
