<template>
  <div class="selector">
    <h1>Selector</h1>
    <div class="content">
      <div v-if="users.length" class="users-list">
        <User v-for="user of users" :key="user.steamid" :user="user" @user-selected="toggleUser"/>
      </div>
      <MySpinner v-show="!imagesLoaded" class="spinner" />
      <div v-if="games.length" class="games-list">
        <Game v-show="imagesLoaded" v-for="game of available_games" :key="game.appid" :game="game" @img-loaded="loadImage" />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '../services/api';
import Game from '../components/Game.vue';
import User from '../components/User.vue';
import MySpinner from '../components/Spinner.vue';

export default {
  name: 'MySelector',
  components: { Game, User, MySpinner },
  data() {
    return {
      users: [],
      games: [],
      selected_users: [],
      imgLoadCounter: 0
    };
  },
  methods: {
    async init() {
      try {
        const promises = [];
        promises.push(api.getAllGames());
        promises.push(api.getAllUsers());
        const results = await Promise.all(promises);
        [this.games, this.users] = results;
      } catch (err) {
        console.error(err.message);
      }
    },
    toggleUser(steamid) {
      const index = this.selected_users.indexOf(steamid);
      if (index < 0) {
        this.selected_users.push(steamid);
      } else {
        this.selected_users.splice(index, 1);
      }
    },
    loadImage(appid) {
      this.imgLoadCounter++;
      console.log(`${this.imgLoadCounter}/${this.games.length}`);
    }
  },
  computed: {
    available_games() {
      let games = this.games.slice();
      for (const user of this.selected_users) {
        games = games.filter(game => !!game.users.find(u => u.steamid === user));
      }
      return games.sort((a, b) => a.name - b.name);
    },
    imagesLoaded() {
      return this.imgLoadCounter > this.games.length * 0.9;
    }
  },
  created() {
    this.init();
  },
  mounted() {
    document.title = '8zKrieger - Selector';
  }
};
</script>

<style scoped>
.content {
  display: flex;
  flex-flow: column nowrap;
  /* align-items: center; */
}

.spinner {
  align-self: center;
}

.users-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 5px;
}

h1 {
  padding: initial;
}
</style>
