<template>
  <button class="button" :class="{ loading }">
    <template v-if="loading">
      <MySpinner />
    </template>
    <template v-else>
      <span class="button-label">{{ name }}</span>
    </template>
  </button>
</template>

<script>
import MySpinner from './Spinner.vue';
export default {
  name: 'MyButton',
  components: { MySpinner },
  props: {
    name: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.button {
  min-width: 70px;
  height: 30px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  color: var(--main-color);
  background: var(--main-background-color);
  border: 1px solid var(--main-color);
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.button:hover {
  background-color: rgba(var(--main-_background-color), 0.92);
  color: var(--main-color);
  border-color: transparent;
}
.button.disabled {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.38);
  opacity: 1;
  cursor: default;
  pointer-events: none;
}
.button-label {
  height: 24px;
  flex: 1 1 auto;
}

</style>
