<template>
  <div class="home">
    <h1>Home</h1>
    <div class="content">
      <p>Die sogenannten "8z Krieger" (Aids Krieger) sind ein Zusammenschluss von Freunden, Gamern und anderen Banausen,
        die durch die kulturell-künstlerischen Austauschmöglichkeiten im Internet versuchen,
        eine freundliche und harmonische Atmosphäre aufzubauen.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  mounted() {
    document.title = '8zKrieger - Home';
  }
};
</script>

<style scoped>
</style>
