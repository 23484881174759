<template>
  <div class="profile">
    <h1>{{ user.name }}'s Profile</h1>
    <div v-if="!user_games.length" class="section waiting">
      <template v-if="error.length">
        <p>Could not load profile data</p>
        <MyButton @click="get_games" name="Retry" :loading="loading" />
        <!-- <MyToast :message="error" type="error" /> -->
      </template>
      <template v-else>
        <p>Loading, please wait</p>
        <MySpinner />
      </template>
    </div>
    <div v-else>
      <div class="section">
        <p>You can hide your games so they dont show up in the selector</p>
        <p>Click edit and select or unselect your games, dont forget to save!</p>
      </div>
      <div v-if="editMode" class="section">
        <MyButton @click="cancel_update" name="Cancel" />
        <MyButton @click="save_changed_games" name="Save" :loading="loading" />
        <MyToast v-if="error.length" :message="error" type="error" />
      </div>
      <div v-else class="section">
        <MyButton @click="editMode = true" name="Edit" />
      </div>
      <div class="section games-list">
        <MyGame
          v-for="game of user_games"
          :key="game.appid"
          :game="game"
          :editMode="editMode"
          @game-clicked="update_changed_games"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from '../components/Button.vue';
import MyToast from '../components/Toast.vue';
import MyGame from '../components/Game.vue';
import MySpinner from '../components/Spinner.vue';
import * as api from '../services/api';
export default {
  components: { MyButton, MyGame, MyToast, MySpinner },
  name: 'MyProfile',
  data() {
    return {
      changed_games: [],
      user_games: [],
      editMode: false,
      loading: false,
      error: {
        type: String,
        default: ''
      }
    };
  },
  methods: {
    get_games() {
      this.error = '';
      api.getMyGames()
        .then((games) => {
          this.user_games = games;
        })
        .catch((err) => {
          console.error(err.message);
          this.error = err.message;
        });
    },
    update_changed_games(appid) {
      this.toggle_game(appid);
      const index = this.changed_games.indexOf(appid);
      if (index > -1) this.changed_games.splice(index, 1);
      else this.changed_games.push(appid);
    },
    save_changed_games() {
      this.loading = true;
      this.error = '';
      if (this.changed_games.length === 0) {
        this.editMode = false;
        this.loading = false;
        return;
      }
      api.updateMyGames(this.changed_games.map((game) => ({ appid: game })))
        .then(() => {
          this.changed_games = [];
          this.editMode = false;
        })
        .catch((err) => {
          console.error(err);
          this.error = err.message;
        })
        .then(() => {
          this.loading = false;
        });
    },
    cancel_update() {
      this.editMode = false;
      for (let i = 0; i < this.changed_games.length; i++) {
        this.toggle_game(this.changed_games[i]);
      }
      this.changed_games = [];
    },
    toggle_game(appid) {
      const game = this.user_games.find((game) => game.appid === appid);
      if (game) game.hidden = !game.hidden;
      else console.error('something went terribly wrong');
    }
  },
  computed: {
    user() {
      return this.$store.state.user || {};
    }
  },
  created() {
    this.get_games();
  },
  mounted() {
    document.title = '8zKrieger - Profile';
  }
};
</script>

<style scoped>
.games-list {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.waiting {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.section {
  margin: 10px 0;
}
</style>
